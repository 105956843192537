import React from 'react';
import SellerInquiry from '@/components/organisms/Inquiry/SellerInquiry';
import withInitializeApp from '@/utils/hoc/withInitializeApp';
import SellerHeader from '@/components/atoms/Header/SellerHeader';
import SellerFooter from '@/components/atoms/Footer/SellerFooter';
import { Wrapper } from '@/components/layout/common';

const SellerInquiryPage = () => {
  return (
    <Wrapper>
      <SellerHeader displayPattern="logo" />
      <SellerInquiry />
      <SellerFooter />
    </Wrapper>
  );
};

export default withInitializeApp(SellerInquiryPage);
