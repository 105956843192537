import React, { memo } from 'react';

const PhoneIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#d1a827">
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M21 15.46l-5.27-.61-2.52 2.52c-2.83-1.44-5.15-3.75-6.59-6.59l2.53-2.53L8.54 3H3.03C2.45 13.18 10.82 21.55 21 20.97v-5.51z" />
    </svg>
  );
};

export default memo(PhoneIcon);
