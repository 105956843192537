import PhoneIcon from '@/components/atoms/Icons/PhoneIcon';
import { Breakpoints, ContactTel } from '@/constants/constants';
import theme from '@/styles/theme';
import React, { memo } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 60px;
  vertical-align: top;
  padding: 30px 0;
  background: #f7f7f7;
  border-radius: 10px;
  color: #333333;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    max-width: 100%;
    padding: 20px 5px;
    vertical-align: baseline;
  }
`;

const Description = styled.p`
  margin-bottom: 20px;
  font: ${theme.fonts.bold_16_27};
  color: #4c586f;
`;

const PhoneNumberContainer = styled.div`
  margin-bottom: 11px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const PhoneNumber = styled.span`
  margin-left: 10px;
  font: ${theme.fonts.black_30_45};
  color: #d1a827;
`;

const ReceptionTimeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ReceptionTimeLabel = styled.span`
  margin-right: 20px;
  font: ${theme.fonts.bold_16_27};
  color: #333333;
`;

const ReceptionTime = styled.span`
  font: ${theme.fonts.normal_16_27};
  color: #333333;
`;

const TelArea = () => {
  return (
    <Wrapper>
      <Description>お電話でのお問合せ・ご相談は</Description>
      <PhoneNumberContainer>
        <PhoneIcon />
        <PhoneNumber>{ContactTel}</PhoneNumber>
      </PhoneNumberContainer>
      <ReceptionTimeContainer>
        <ReceptionTimeLabel>受付時間</ReceptionTimeLabel>
        <ReceptionTime>月~金 10:00~19:00</ReceptionTime>
      </ReceptionTimeContainer>
    </Wrapper>
  );
};

export default memo(TelArea);
