import React from 'react';
import { navigate } from 'gatsby';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Inquiry, InquiryType } from 'openapi/kusabi-frontgw-spec';
import { InquiryRequestContents } from '@/constants/constants';
import { useDispatch } from 'react-redux';
import * as inquiry from '@/state/modules/inquiry';
import { Routings } from '@/common/routings';
import { FormState, schema } from '../state';
import WrappedSubmitButton from '@/components/atoms/SubmitButton/WrappedSubmitButton';
import TelArea from '../CommonComponent/TelArea';
import MailArea from '../CommonComponent/MailArea';
import FormLabel from '@/components/atoms/FormLabel';
import TextField from '@/components/atoms/TextField';
import Select from '@/components/atoms/Select';
import {
  ContentWrapper,
  Content,
  Description,
  Table,
  TBody,
  Th,
  Tr,
  Td,
  InnerTd,
  NameAreaContainer,
  NameArea,
  NameTextFieldContainer,
  NameTextFieldWrapper,
  NameTextField,
  TextArea,
  EmailCaution,
} from '@/components/atoms/CommonStyles';
import PageTitle from '@/components/atoms/PageTitle';
import { getPartneridForSessionStorage } from '@/common/utilities';
import Terms from '@/components/atoms/Terms';
import { Main } from '@/components/layout/common';
import ErrorMessage from '@/components/atoms/ErrorMessage';

const SellerInquiry = () => {
  const dispatch = useDispatch();

  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm<FormState>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      type: InquiryType.SELL,
      request: InquiryRequestContents.seller[0],
    },
  });

  const onSubmit: SubmitHandler<FormState> = (data) => {
    const param: Inquiry = {
      firstName: data.firstName,
      lastName: data.lastName,
      firstNameKana: data.firstNameKana,
      lastNameKana: data.lastNameKana,
      mailAddress: data.mailAddress,
      phoneNumber: data.phoneNumber,
      type: data.type as InquiryType,
      request: data.request,
      content: data.content,
      partnerId: getPartneridForSessionStorage(),
    };

    dispatch(inquiry.operations.postInquiries(param));
    navigate(Routings.sellerCompleted.location);
  };

  return (
    <Main>
      <PageTitle>お問合せ</PageTitle>

      <ContentWrapper>
        <Content>
          <Description>お問合せ・ご相談内容をご入力ください</Description>
          <Table>
            <TBody>
              <Tr>
                <Th>
                  <FormLabel value="お名前" isRequired />
                </Th>
                <Td>
                  <InnerTd>
                    <NameAreaContainer>
                      <NameArea>
                        <NameTextFieldContainer>
                          <NameTextFieldWrapper>
                            <label htmlFor="lastName">姓</label>
                            <Controller
                              name="lastName"
                              control={control}
                              render={({ field }) => <NameTextField id="lastName" type="text" {...field} />}
                            />
                          </NameTextFieldWrapper>
                          {errors?.lastName && <ErrorMessage>{errors.lastName.message}</ErrorMessage>}
                        </NameTextFieldContainer>

                        <NameTextFieldContainer>
                          <NameTextFieldWrapper>
                            <label htmlFor="firstName">名</label>
                            <Controller
                              name="firstName"
                              control={control}
                              render={({ field }) => <NameTextField id="firstName" type="text" {...field} />}
                            />
                          </NameTextFieldWrapper>
                          {errors?.firstName && <ErrorMessage>{errors.firstName.message}</ErrorMessage>}
                        </NameTextFieldContainer>
                      </NameArea>

                      <NameArea>
                        <NameTextFieldContainer>
                          <NameTextFieldWrapper>
                            <label htmlFor="lastNameKana">せい</label>
                            <Controller
                              name="lastNameKana"
                              control={control}
                              render={({ field }) => <NameTextField id="lastNameKana" type="text" {...field} />}
                            />
                          </NameTextFieldWrapper>
                          {errors?.lastNameKana && <ErrorMessage>{errors.lastNameKana.message}</ErrorMessage>}
                        </NameTextFieldContainer>
                        <NameTextFieldContainer>
                          <NameTextFieldWrapper>
                            <label htmlFor="firstNameKana">めい</label>
                            <Controller
                              name="firstNameKana"
                              control={control}
                              render={({ field }) => <NameTextField id="firstNameKana" type="text" {...field} />}
                            />
                          </NameTextFieldWrapper>
                          {errors?.firstNameKana && <ErrorMessage>{errors.firstNameKana.message}</ErrorMessage>}
                        </NameTextFieldContainer>
                      </NameArea>
                    </NameAreaContainer>
                  </InnerTd>
                </Td>
              </Tr>
              <Tr>
                <Th>
                  <FormLabel value="メールアドレス" isRequired htmlFor="mailAddress" />
                </Th>
                <Td>
                  <InnerTd>
                    <Controller
                      name="mailAddress"
                      control={control}
                      render={({ field }) => <TextField type="text" id="mailAddress" {...field} />}
                    />
                    <EmailCaution>※キャリアメールはご登録いただけません。</EmailCaution>
                    {errors?.mailAddress && <ErrorMessage>{errors.mailAddress.message}</ErrorMessage>}
                  </InnerTd>
                </Td>
              </Tr>
              <Tr>
                <Th>
                  <FormLabel value="電話番号" htmlFor="phoneNumber" />
                </Th>
                <Td>
                  <InnerTd>
                    <Controller
                      name="phoneNumber"
                      control={control}
                      render={({ field }) => <TextField type="text" id="phoneNumber" {...field} />}
                    />
                    {errors?.phoneNumber && <ErrorMessage>{errors.phoneNumber.message}</ErrorMessage>}
                  </InnerTd>
                </Td>
              </Tr>
              <Tr>
                <Th>
                  <FormLabel value="ご用件" htmlFor="request" />
                </Th>
                <Td>
                  <InnerTd>
                    <Controller
                      name="request"
                      control={control}
                      render={({ field }) => (
                        <Select {...field} id="request">
                          {InquiryRequestContents.seller.map((request, i) => (
                            <option key={i} value={request}>
                              {request}
                            </option>
                          ))}
                        </Select>
                      )}
                    />
                  </InnerTd>
                </Td>
              </Tr>
              <Tr>
                <Th>
                  <FormLabel value="お問合せ内容" htmlFor="content" />
                </Th>
                <Td>
                  <div>
                    <Controller
                      name="content"
                      control={control}
                      render={({ field }) => <TextArea id="content" {...field} />}
                    />
                  </div>
                  {errors?.content && <ErrorMessage>{errors.content.message}</ErrorMessage>}
                </Td>
              </Tr>
            </TBody>
          </Table>

          <Terms />

          <WrappedSubmitButton withTerms label="フォームの内容を送信する" onClick={handleSubmit(onSubmit)} />
          <TelArea />
          <MailArea />
        </Content>
      </ContentWrapper>
    </Main>
  );
};

export default SellerInquiry;
