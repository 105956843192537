import { Breakpoints } from '@/constants/constants';
import React, { memo } from 'react';
import styled from 'styled-components';
import SubmitButton from '.';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 60px;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
  }
`;

type Props = {
  withTerms?: boolean;
  label: string;
  onClick: () => void;
};

const WrappedSubmitButton = (props: Props) => (
  <Wrapper>
    <SubmitButton {...props} />
  </Wrapper>
);

export default memo(WrappedSubmitButton);
