import MailIcon from '@/components/atoms/Icons/MailIcon';
import { Breakpoints, ContactMailAddress } from '@/constants/constants';
import theme from '@/styles/theme';
import React, { memo } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 60px;
  vertical-align: top;
  padding: 30px 0;
  background: #f7f7f7;
  border-radius: 10px;
  color: #333333;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    max-width: 100%;
    padding: 20px 5px;
    vertical-align: baseline;
  }
`;

const Description = styled.p`
  margin-bottom: 25px;
  font: ${theme.fonts.bold_16_27};
  color: #4c586f;
`;

const Anchor = styled.a`
  text-decoration: none;
`;

const MailAddressContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const MailAddress = styled.span`
  margin-left: 10px;
  font: ${theme.fonts.black_20_45};
  color: #d1a827;
`;

const MailArea = () => {
  return (
    <Wrapper>
      <Description>メールでのお問合せ・ご相談は</Description>
      <Anchor href={`mailto:${ContactMailAddress}`}>
        <MailAddressContainer>
          <MailIcon />
          <MailAddress>{ContactMailAddress}</MailAddress>
        </MailAddressContainer>
      </Anchor>
    </Wrapper>
  );
};

export default memo(MailArea);
